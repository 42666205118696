﻿@charset "UTF-8";
$font-path: "../public/fonts/";
$font-name: "fontello";

@font-face {
    font-family: 'fontello';
    src: url('#{$font-path}#{$font-name}.eot?34988391');
    src: url('#{$font-path}#{$font-name}.eot?34988391#iefix') format('embedded-opentype'), 
        url('#{$font-path}#{$font-name}.woff2?34988391') format('woff2'), 
        url('#{$font-path}#{$font-name}.woff?34988391') format('woff'), 
        url('#{$font-path}#{$font-name}.ttf?34988391') format('truetype'), 
        url('#{$font-path}#{$font-name}.svg?34988391#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?34988391#fontello') format('svg');
  }
}
*/

@font-face {
    font-family: 'Julius Sans One';
    src: url('#{$font-path}juliussansone-regular-webfont.eot');
    src: url('#{$font-path}juliussansone-regular-webfont.eot?#iefix') format('embedded-opentype'), url('#{$font-path}juliussansone-regular-webfont.woff2') format('woff2'), url('#{$font-path}juliussansone-regular-webfont.woff') format('woff'), url('#{$font-path}juliussansone-regular-webfont.ttf') format('truetype'), url('#{$font-path}juliussansone-regular-webfont.svg#julius_sans_oneregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-ok:before {
    content: '\e800';
}
/* '' */
.icon-cancel:before {
    content: '\e801';
}
/* '' */
.icon-right-open:before {
    content: '\e802';
}
/* '' */
.icon-left-open:before {
    content: '\e803';
}
/* '' */
.icon-down-open:before {
    content: '\e804';
}
/* '' */
.icon-up-open:before {
    content: '\e805';
}
/* '' */
.icon-cog:before {
    content: '\e806';
}
/* '' */
.icon-heart:before {
    content: '\e807';
}
/* '' */
.icon-heart-empty:before {
    content: '\e808';
}
/* '' */
.icon-star:before {
    content: '\e809';
}
/* '' */
.icon-star-empty:before {
    content: '\e80a';
}
/* '' */
.icon-user:before {
    content: '\e80b';
}
/* '' */
.icon-search:before {
    content: '\e80c';
}
/* '' */
.icon-tags:before {
    content: '\e80d';
}
/* '' */
.icon-link-ext:before {
    content: '\f08e';
}
/* '' */
.icon-twitter:before {
    content: '\f099';
}
/* '' */
.icon-facebook:before {
    content: '\f09a';
}
/* '' */
.icon-rss:before {
    content: '\f09e';
}
/* '' */
.icon-menu:before {
    content: '\f0c9';
}
/* '' */
.icon-doc-inv:before {
    content: '\f15b';
}
/* '' */
.icon-youtube-play:before {
    content: '\f16a';
}
/* '' */
.icon-instagram:before {
    content: '\f16d';
}
/* '' */
.icon-file-pdf:before {
    content: '\f1c1';
}
/* '' */
.icon-share:before {
    content: '\f1e0';
}
/* '' */
.icon-pinterest:before {
    content: '\f231';
}
/* '' */
.icon-shopping-basket:before {
    content: '\f291';
}
/* '' */
.icon-etsy:before {
    content: '\f2d7';
}
/* '' */
.icon-ravelry:before {
    content: '\f2d9';
}
/* '' */
@import "../node_modules/bootstrap/scss/_functions.scss";

$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$input-font-family: $font-family-sans-serif;
$font-family-brand: 'Julius Sans One', $font-family-sans-serif;

/*$claret: #550004;
$matador: #d40001;
$tomato: #b00808;
$raspberry: #a03352;
$fushia: #a0084d;
$boysenberry: #8a0c55;
$storm-blue: #2e78a9;
$empire: #034d7c;*/
$petrol: #00747D;
/*$grass-green: #60b849;
$sunshine: #eea000;
$spice: #e05c25;
$gold: #ad7417;*/

$primary: $petrol;
$secondary: #222;
$modal-header-bg: $primary;
$body-bg: lighten($primary, 80%);
$link-color: $secondary;

@import "../node_modules/bootstrap/scss/_variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "fonts.scss";
@import "sidebar.scss";

#wrapper {
    height: 100%;
}

#page-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.content {
    flex: 1 0 auto;
}


h1 {
    text-align: center;
}

#top {
    background-color:$primary;
    padding:0.5rem;
    font-family:$font-family-brand;
    margin-bottom:1rem;
    li {
        margin-left:1rem;
    }

    a {
        color:$white;
        text-decoration:none;
    }

    #brand-heading {
        font-size:1.2em;
        margin-left:1rem;
        padding-top:5px;
    }
}

footer {
    flex-shrink: 0;
    background-color: $primary;
    color: $white;
}

#menu-toggle {
    padding: 0.5rem;
    border: none;
    background-color: darken($primary,10%);
}

#scroll_top {
    position: fixed;
    right: 50px;
    bottom: 50px;
    box-shadow: 0 4px 8px $gray-800;
    padding: 0.5rem;
    border: none;
    background-color: darken($primary,10%);

    @media (max-width: 800px) {
        right: 15px;
        bottom: 15px;
    }
}

.navbar, .nav-link, .btn {
    font-family: $font-family-brand;
}

h1, h2, h3, h4, h5 {
    font-family: $font-family-brand;
    color: $secondary;
}

a {
    font-weight: bold;
    &:hover {
        color: $black;
    }
}

.card {
    border: none;
}

.list-linktree {
    @extend .list-unstyled;
    text-align: center;

    li {
        a {
            @extend .btn, .btn-primary, .m-1;
            width: 100%;
            max-width: 400px;
        }
    }

    li:nth-child(1) {
        a {
            background-color: darken($primary, 9%);
        }
    }

    li:nth-child(2) {
        a {
            background-color: darken($primary, 6%);
        }
    }

    li:nth-child(3) {
        a {
            background-color: darken($primary, 3%);
        }
    }

    li:nth-child(4) {
        a {
            background-color: $primary;
        }
    }

    li:nth-child(5) {
        a {
            background-color: lighten($primary, 3%);
        }
    }

    li:nth-child(6) {
        a {
            background-color: lighten($primary, 6%);
        }
    }

    li:nth-child(7) {
        a {
            background-color: lighten($primary, 9%);
        }
    }
}

.colour-picker {
    width: 50px;
    height: 50px;
    border: 1px solid $gray-800;
    cursor: pointer;
    margin: auto;
}

.list-social-link {
    @extend .list-inline;
    margin-bottom:0;
    li {
        @extend .list-inline-item;

        a {
            @extend .btn, .btn-primary;
            border: none;
            font-size: 2em;
            padding: 0.4rem;
        }
    }
}

.placeholder {
    background-color: $gray-200;
    display: block;
    width: 100%;
    min-height: 1em;
}
/* #D1FFE3 */

.table-print {
    td, th {
        padding: 10px;
        border-bottom: 1px solid #000;
    }
}